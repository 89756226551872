<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="top">
      <div class="title">辅导服务</div>
      <div class="name">{{ inform.name }}</div>
      <div class="count">招聘人数：{{ inform.count }}人</div>
      <div class="time">
        报名时间：{{ inform.startTime }} 到 {{ inform.endTime }}
      </div>
    </div>
    <div class="explain">
      <div class="title">报名须知</div>
      <div class="item">1、服务包含线下辅导服务</div>
      <div class="item">2、提交申请后请保持电话畅通等待客服回访</div>
      <div class="item">3、支付金额为定金，具体价格线下沟通金额为主</div>
    </div>
    <div class="amount">
      <div class="title">服务费用</div>
      <div class="item">
        <div class="left">定金</div>
        <div class="right color-s2">{{ expense.curPrice }}元</div>
      </div>
      <!-- <div class="item">
        <div class="left">折扣</div>
        <div class="right color-s2">{{ expense.rate * 10 }}折</div>
      </div>
      <div class="item">
        <div class="left">现价</div>
        <div class="right color-s2">{{ expense.curPrice }}元</div>
      </div>
      <div class="item">
        <div class="left">优惠劵</div>
        <div class="right">暂无</div>
      </div> -->
      <div class="item">
        <div class="left">支付金额</div>
        <div class="right color-s2">{{ expense.curPrice }}元</div>
      </div>
      <div class="button">
        <van-button
          type="primary"
          :color="COLOR_M"
          class="btn"
          @click="pay"
          icon="certificate"
          size="small"
          block
          >立即报名</van-button
        >
      </div>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="IFM" module="IFM_TOTUR_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
