import { Tag, Icon } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        [Tag.name]: Tag,
        [Icon.name]: Icon
    },
    data () {
        return {
            code: '',
            payOrderCode: '',
            expense: {
                fullPrice: 99,
                rate: 0.1,
                curPrice: 100
            },
            inform: {}
        }
    },
    mounted () {
        var query = this.$route.query
        this.code = query.code
        this.retrieveInform()
    },
    methods: {
        async retrieveInform () {
            var pd = { code: this.code }
            var { data: res } = await this.$http.post(this.BMS_URL + '/recruit/informAPC/retrieveInform', this.$qs.stringify(pd))
            if (res.status === '200') {
                console.log(res.data)
                this.inform = res.data
            }
        },
        async payment () {
            this.loadingShow = true
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            var pd = {
                appKey: 'WPP_MOC',
                amount: this.expense.curPrice,
                attach: '',
                description: '公招报名支付',
                body: '公招报名支付',
                detail: '',
                business: this.code,
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createWJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        } else {
                            that.loadingShow = false
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var that = this
            var pd = { appKey: 'WPP_MOC', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付成功'
                })
                that.createOrder()
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付失败'
                })
            }
        },
        async createOrder () {
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
            var pd = {
                informCode: this.code,
                onlineAmount: this.expense.curPrice,
                operatorCode: operator,
                sellerCode: seller,
                refereeCode: referee,
                type: 'CAH'
            }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/informAPC/createOrder', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '报名保存成功'
                })
                this.$router.go(-1)
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '报名保存失败'
                })
            }
            this.loadingShow = false
        }
    }
}
